.oferta-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-doubleunit) var(--dl-space-space-fiveunits);
  padding-bottom: 0px;
}
.karta {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  margin-top: 32px;
  flex-direction: row;
  justify-content: space-between;
}
.obraz-karty {
  width: 40%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
}
.grafika {
  width: 100%;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  transition: 1s;
}
.karta:hover .grafika {
  z-index: 0;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}
.oferta {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 55%;
}
.etapy-tytul {
  font-size: 40px;
  text-align: center;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.etapy {
  display: grid;
  grid-gap: var(--dl-space-space-fiveunits);
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin: var(--dl-space-space-doubleunit) 0px;
}
.etap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.etap:hover .ikona {
  fill: var(--dl-color-PMA);
  -webkit-transform: scale(1.5) rotate(360deg);
  -moz-transform: scale(1.5) rotate(360deg);
  -ms-transform: scale(1.5) rotate(360deg);
  -o-transform: scale(1.5) rotate(360deg);
  transform: scale(1.5) rotate(360deg);
}
.etap:hover .etap-naglowek {
  color: var(--dl-color-PMA);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.ikona {
  display: flex;
  align-items: center;
  justify-content: center;
  position: initial;
  width: 40px;
  height: auto;
  fill: var(--dl-color-gray-500);
  margin-right: var(--dl-space-space-fiveunits);
  transition: 0.3s;
}
.etap-naglowek {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
  transition: 0.3s;
}
.etap-opis {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 991px) {
  .etap-naglowek {
    font-size: 24px;
  }
  .karta {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .obraz-karty {
    height: 380px;
    width: 70%;
    margin-bottom: var(--dl-space-space-fiveunits);
    object-position: center center;
  }
  .oferta {
    width: 100%;
  }
  .ikona {
    width: 32px;
    margin-right: var(--dl-space-space-doubleunit);
  }
}
@media (max-width: 767px) {
  .obraz-karty {
    width: 100%;
    height: auto;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .grafika {
    align-self: center;
  }
  .oferta {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .etapy {
    grid-template-columns: 1fr;
    grid-gap: var(--dl-space-space-unit);
    margin: 0px;
  }
  .etap {
    flex-direction: column;
  }
  .etap-naglowek {
    font-size: 22px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .etap-opis {
    align-items: center;
  }
  .ikona-container {
    margin: var(--dl-space-space-tripleunit) 0px var(--dl-space-space-doubleunit) 0px;
  }
  .ikona {
    width: 32px;
    height: 32px;
    margin-right: 0px;
  }
  .etap-opis {
    flex-direction: column;
  }
}
@media (max-width: 479px) {
  .obraz-karty {
    align-items: center;
    height: auto;
  }
  .oferta-container {
    margin-bottom: 0px;
  }
  .oferta {
    text-align: center;
    align-items: center;
  }
  .naglowek {
    align-self: center;
  }
  .tytul {
    font-size: 22px;
  }
  .opis {
    text-align: center;
  }
  .etapy-tytul {
    font-size: 26px;
  }
  .ikona {
    width: 42px;
    height: 42px;
  }
  .etap-opis {
    flex-direction: column;
  }
  .etap-naglowek {
    font-size: 20px;
  }
}