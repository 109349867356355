.realizacje-container {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 width: 100%;
 padding: var(--dl-space-space-doubleunit) var(--dl-space-space-fiveunits);
 max-width: var(--dl-size-size-maxcontent);
}
.realizacje {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--dl-space-space-doubleunit);
  margin-top: var(--dl-space-space-tripleunit);
}
.karta {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ramka-karta {
  width: 100%;
  height: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;  
  border-radius: var(--dl-radius-radius-radius8);
}
.obraz-karta {
  width: 100%;
  height: 500px;
  object-fit: cover;
  overflow: hidden;
  transition: 0.5s;
}
.karta:hover .obraz-karta {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.tytul-karta {
  align-self: flex-start;
  font-size: 22px;
  font-weight: 600;
  color: var(--dl-color-PMA);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.opis-karta {
  align-self: flex-start;
  text-align: justify;
  font-size: 16px;
}
.opinie {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: var(--dl-space-space-fiveunits) var(--dl-space-space-fiveunits);
  margin-top: var(--dl-space-space-fiveunits);
  background-color: #f1f1f1;
}
.max-content-width-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  flex-direction: column;
}
.opinie-google {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin-top: var(--dl-space-space-fiveunits);
}
.napisz-opinie {
  width: 90%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: flex-start;
}
@media (max-width: 991px) {
  .realizacje {
    margin-top: var(--dl-space-space-unit);
    grid-template-columns: 1fr 1fr;
  }
  .tytul-karta {
    font-size: 20px;
  }
  .obraz-karta {
    height: 450px;
  }
  .napisz-opinie {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .karta {
    flex-direction: column;
  }
  .ramka-karta {
    flex-direction: column;
  }
  .obraz-karta {
    height: 400px;
  }
  .tytul-karta {
    font-size: 18px;
  }
  .OpisKarty {
    font-size: 14px;
  }
  .opinie {
    padding-left: 32px;
    padding-right: 32px;
  }
  .opinie-google {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 650px) {
  .realizacje {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 479px) {
  .tytul-karta {
    font-size: 16px;
  }
  .obraz-karta {
    height: 350px;
  }
  .opis-karta {
    font-size: 12px;
  }
  .opinie {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
}
