.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(24, 24, 24, 0.63);
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  .modal-body {
    align-self: center;
    height: auto;
    width: auto;
  }
  