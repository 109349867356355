.o-firmie-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: var(--dl-space-space-doubleunit);
}
.o-firmie {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-doubleunit) var(--dl-space-space-fiveunits);
}
.logoduze {
  display: flex;
  width: 30%;
  justify-content: center;
  flex-shrink: 0;
  transition: 0.8s;
  margin-right: 32px;
}
.logo-obraz {
  object-fit: scale-down;
  height: auto;
  width: 90%;
  border-radius: var(--dl-radius-radius-radius8);
}
.logoduze:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.opis {
  display: flex;
  flex-direction: column;
}
.tekst {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.wspolpraca {
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--dl-space-space-eightunits) 0px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.zapytania {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
}
.adres {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.adres-wiersz {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-top: var(--dl-space-space-unit);
  cursor: pointer;
  text-align: center;
  font-size: 21px;
  transition: 0.3s;
}
.adres-wiersz:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.ikona {
  width: 24px;
  height: 24px;
  fill: var(--dl-color-gray-500);
  margin-right: var(--dl-space-space-doubleunit);
  align-self: center;
  justify-self: center;
  align-items: center;
  transition: 0.3s;
}
.adres-wiersz:hover .ikona {
  fill: var(--dl-color-PMA);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform:   rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.vcard {
  width: 400px;
  max-width: 80vw;
  height: auto;
}
@media (max-width: 991px) {
  .tytul.container {
    align-items: center;
  }
  .o-firmie {
    padding: var(--dl-space-space-doubleunit) var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .logoduze {
    order: 2;
    width: 40%;
    padding-bottom: var(--dl-space-space-doubleunit);
    padding-top: 0px;
  }
  .opis {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .adres-wiersz {
    justify-content: center;
  }
  .adres-wiersz:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@media (max-width: 767px) {
  .logoduze {
    width: 60%;
    margin: 0px;
  }
  .adres-wiersz {
    font-size: 18px;
  }
}
@media (max-width: 479px) {
  .o-firmie {
    padding-top: 32px;
  }
  .opis {
    margin-bottom: var(--dl-space-space-unit);
  }
  .adres-wiersz {
    font-size: 16px;
  }
}
