  .polityka-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
  }
  .polityka {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    max-width: var(--dl-size-size-maxcontent);
    padding: 0px var(--dl-space-space-fiveunits);
  }
  .opis {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: var(--dl-space-space-fiveunits);
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .logoduze {
    width: 30%;
    height: auto;
    margin-bottom: var(--dl-space-space-fourunits);
    transition: 0.3s;
  }
  .logoduze:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  @media (max-width: 991px) {
    .logoduze {
      order: 2;
      width: 40%;
      padding-top: var(--dl-space-space-doubleunit);
    }
    .opis {
      align-items: center;
      margin-left: 0px;
      margin-right: 0px;
    }
    li {
      text-align: justify;
    }
  }
  @media (max-width: 767px) {
    .logoduze {
      width: 60%;
    }
    .li {
      text-align: justify;
    }
  }
  @media (max-width: 479px) {
    ol {
      padding: 0px 0px 0px 30px;
    }
    li {
      text-align: justify;
    }
    .logoduze {
      width: 40%;
    }
  }
  