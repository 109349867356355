.powitanie-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-fiveunits) var(--dl-space-space-fiveunits);
}
.powitanie {
  display: flex;
  width: 50%;
  height: auto;
  flex-direction: column;
}
.banner {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 45%;
  overflow: hidden;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
}
.banner-img {
  display: flex;
  object-fit: cover;
  transition: 1s;
}
.banner-img:hover {
  z-index: 0;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
.branze {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
  padding-bottom: var(--dl-space-space-tripleunit);
  font-size: 20px;
  font-weight: normal;
}
.branza {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.ikona-branza {
  fill: var(--dl-color-PMA);
  width: 32px;
  height: 32px;
  padding-right: var(--dl-space-space-unit);
}
.sterowniki {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-fiveunits) var(--dl-space-space-fiveunits);
  margin: var(--dl-space-space-fiveunits) 0px;
  padding-bottom: 0px;
}
.ramka-carel {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sterownik {
  width: 100%;
  display: flex;
  box-shadow: 0px 9px 10px 0px rgba(179, 179, 179, 1);
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-top: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.naglowek-carel {
  width: 100%;
  text-align: center;
  align-self: center;
  padding: var(--dl-space-space-doubleunit) var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--dl-color-gray-900);
}
.carel-continer {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.karuzela {
  justify-content: center;
  align-self: center;
  width: 80%;
  align-items: center;
  justify-content: center;
  height: auto;
}
.naglowek-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.systemy-sterowania {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}
.system {
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.system-element {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: center;
}
.system-ikona {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.system-tekst {
  text-align: left;
  font-size: 20px;
}
.producenci {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-fiveunits) var(--dl-space-space-fiveunits);
  margin-top: var(--dl-space-space-tripleunit);
  margin-bottom: 0px;
}
.logo-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: var(--dl-space-space-fourunits);
}
.link-icon {
  display: contents;
}
.logo {
  width: 13%;
  height: max-content;
  object-fit: cover;
  transition: 0.5s;
  text-decoration: none;
}
.logo:hover {
  z-index: 0;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 991px) {
  .powitanie-container {
    padding:  var(--dl-space-space-fiveunits);
  }
  .powitanie {
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
  }
  .system-tekst {
    font-size: 18px;
  }
  .system {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-tripleunit);
    padding-top: 0px;
  }
  .system-element {
    margin-bottom: var(--dl-space-space-unit);
  }
  .producenci {
    padding:  var(--dl-space-space-fiveunits);
  }
  .logo-container {
    margin-top: var(--dl-space-space-doubleunit);
    justify-content: space-between;
  }
  .sterowniki {
    padding:  var(--dl-space-space-fiveunits);
  }
  .karuzela {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .powitanie-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .powitanie {
    width: 100%;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .branze {
    padding-left: 0px;
    font-size: 16px;
  }
  .branza {
    justify-content: center;
  }
  .powitanie-koniec {
    text-align: center;
  }
  .banner {
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
  .banner-img {
    height: 400px;
  }
  .naglowek-container {
    width: 100%;
  }
  .systemy-sterowania {
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .system {
    width: 90%;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .ramka-carel {
    width: 100%;
    flex-direction: column;
  }
  .naglowek-carel {
    text-align: center;
  }
  .logo {
    width: 27%;
  }
}

@media (max-width: 550px) {
  .logo-container {
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .logo {
    width: 30%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .logo1 {
    width: 30%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .logo2 {
    width: 30%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .logo3 {
    width: 30%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .logo4 {
    width: 30%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .logo5 {
    width: 30%;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}

@media (max-width: 479px) {
  .powitanie {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .powitanie-koniec {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .banner {
    width: 80%;
  }
  .system {
    width: 100%;
  }
  .ramka-carel {
    padding-left: 0px;
  }
  .naglowek-carel {
    text-align: center;
  }
}
