/* loading bar */
.loadingBar {
  display: block;
	height: 5px;
	background: #27c4f5 -webkit-gradient(linear, left top, right top, from(#27c4f5), color-stop(#a307ba), color-stop(#fd8d32), color-stop(#70c050), to(#27c4f5));
	background: #27c4f5 -webkit-linear-gradient(left, #27c4f5, #a307ba, #fd8d32, #70c050, #27c4f5);
	background: #27c4f5 linear-gradient(to right, #27c4f5, #a307ba, #fd8d32, #70c050, #27c4f5);
	background-size: 500%;
	-webkit-animation: 2s linear infinite LoadingBarProgress, .5s ease-out LoadingBarEnter;
	animation: 2s linear infinite LoadingBarProgress, .5s ease-out LoadingBarEnter;
	-webkit-transform-origin: left;
	transform-origin: left;
	width: 100%;
	z-index: 99;
  margin: var(--dl-space-space-tripleunit) 0px;
}
@keyframes LoadingBarProgress {
	0% {
		background-position: 0% 0
	}
	to {
		background-position: 125% 0
	}
}
@keyframes LoadingBarEnter {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0)
	}
	to {
		-webkit-transform: scaleX(1);
		transform: scaleX(1)
	}
}
.loadingBar-hidden {
  display: none;
}

/* reszta */
.kontakt {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-doubleunit) var(--dl-space-space-fiveunits);
  padding-bottom: 0px;
}
.wiadomosc-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-tenunits);
  gap: 40px
}
.formularz-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.formularz {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-right: var(--dl-space-space-fiveunits);
}
.form {
  width: 100%;
}
.pole-formularz {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.ikona {
  display: flex;
  width: 32px;
  height: 32px;
  fill: var(--dl-color-gray-500);
  margin-right: var(--dl-space-space-doubleunit);
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.pole-formularz:hover .ikona,
.pole-formularz input:focus + .ikona,
.pole-formularz textarea:focus + .ikona {
  fill: var(--dl-color-PMA);
  -webkit-transform: rotate(360deg) scale(1.2);
  -moz-transform: rotate(360deg) scale(1.2);
  -ms-transform:   rotate(360deg) scale(1.2);
  -o-transform: rotate(360deg) scale(1.2);
  transform: rotate(360deg) scale(1.2);
}
.input {
  text-align: left;
  padding: var(--dl-space-space-doubleunit);
  width: 100%;
  font-size: 18px;
  border-width: 1px;
  border-color: #CCCCCC;
  border-style: solid;
  border-radius: 8px;
}
.wiadomosc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
}
.RODO {
  display: flex;
  font-size: 13px;
  text-align: justify;
}
.licznik {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  font-size: 16px;
  padding-bottom: var(--dl-space-space-unit);
}
.loader {
  display: flex;
  align-self: center;
  justify-content: center;
  margin: var(--dl-space-space-tripleunit) 0px;
}
.loader-hidden {
  display: none;
}
.polityka {
  font-size: 12px;
  text-align: center;
  color: #256861;
  font-weight: 600;
  text-decoration: underline;
}
.wiadomoscWyslana {
  width: 100%;
  display: flex-end;
  align-self: center;
  align-items: center;
  text-align: center;
  color: #256861;
  font-weight: 600;
  font-size: 22px;
  padding: 0px var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.wiadomoscBlad {
  width: 100%;
  display: flex-end;
  text-align: center;
  text-align: center;
  align-self: center;
  color: #c00000;
  font-weight: 600;
  font-size: 22px;
  padding: 0px var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.wiadomoscNieWyslana {
  display: none;
}
.recaptcha-container {
  width: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  margin-top: var(--dl-space-space-tripleunit)
}
.RECAPTCHA {
  width: 100%;
}
.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: var(--dl-space-space-doubleunit) 0px;
  font-size: 18px;
  font-weight: 600;
}
.alert-icon {
  fill: var(--dl-color-gray-500);
  margin-left: var(--dl-space-space-doubleunit);
  width: 32px;
  height: 32px;
  transition: 0.3s;
}
.alert:hover .alert-icon {
  fill: var(--dl-color-PMA);
  -webkit-transform: rotate(360deg) scale(1.2);
  -moz-transform: rotate(360deg) scale(1.2);
  -ms-transform:   rotate(360deg) scale(1.2);
  -o-transform: rotate(360deg) scale(1.2);
  transform: rotate(360deg) scale(1.2);
}
.mapa {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 500px;
  width: 100%;  
  max-width: 100vw;
  justify-content: center;
  margin: var(--dl-space-space-fiveunits) 0px;
}
.mapa.dojazdu {
  width: 100%;
  height: 100%;
  border: 0px;
}
@media (max-width: 991px) {
  .formularz {
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
  }
  .pole-formularz {
    width: 100%;
  }
  .wiadomosc {
    width: 40%;
  }
  .mapa {
    width: 100%;
    height: 400px;
  }
  .mapa.dojazdu {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .wiadomosc {
    align-items: center;
    width: 50%;
  }
  .input {
    font-size: 14px;
  }
  .RODO {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .formularz-container {
    flex-direction: column;
  }
  .formularz {
    width: 100%;
  }
  .wiadomosc {
    width: 100%;
  }
  .input {
    font-size: 16px;
  }
  .alert {
    flex-direction: column;
    gap: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-tripleunit);
    text-align: center;
  }
}
@media (max-width: 479px) {
  .kontakt {
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .formularz {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .formularz {
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .pole-formularz {
    font-size: 14px;
  }
  .recaptcha-container {
    font-size: 14px;
  }
}
