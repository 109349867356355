:root {
  --dl-color-gray-500: #444444;
  --dl-color-gray-700: #999999;
  --dl-color-gray-800: #cbcbcbff;
  --dl-color-gray-900: #e0e0e0;
  --dl-color-gray-1000: #e9e9e9;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-color-PMA: #256861;
  --dl-radius-radius-round: 50%;
  --dl-size-size-maxcontent: 1414px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-halfunit: 4px;
  --dl-space-space-unit: 8px;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-tripleunit: 24px;
  --dl-space-space-fourunits: 32px;
  --dl-space-space-fiveunits: 40px;
  --dl-space-space-sixunits: 48px;
  --dl-space-space-sevenunits: 56px;
  --dl-space-space-eightunits: 64px;
  --dl-space-space-tenunits: 80px;
}
.background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(117, 117, 117, 0.85);
}
.cookie {
  fill: var(--dl-color-gray-white);
  width: 20%;
  height: 20%;
  z-index: 101;
  padding-bottom: var(--dl-space-space-tenunits);
  filter: drop-shadow(3px 15px 12px rgb(0 0 0 / 0.5));
}
.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.cookie-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 20%;
  margin: 0px var(--dl-space-space-tripleunit);
}
.button-black {
  background-color: #000000;
  border: none !important;
  border-radius: 6px;
  -webkit-box-shadow: 8px 8px 18px -4px rgba(127, 129, 152, 1);
  -moz-box-shadow: 8px 8px 18px -4x rgba(127, 129, 152, 1);
  box-shadow: 8px 8px 18px -4px rgba(127, 129, 152, 1);
  margin-top: var(--dl-space-space-doubleunit);
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  line-height: 15px;
  height: auto;
  padding: 20px 60px;
  box-sizing: border-box;
  transition: 0.3s;
}
.button-black:hover {
  background-color: #256861;
}
.button-white {
  background-color: #ffffff;
  border: none !important;
  border-radius: 6px;
  -webkit-box-shadow: 8px 8px 18px -4px rgba(127, 129, 152, 1);
  -moz-box-shadow: 8px 8px 18px -4x rgba(127, 129, 152, 1);
  box-shadow: 8px 8px 18px -4px rgba(127, 129, 152, 1);
  margin-top: var(--dl-space-space-doubleunit);
  color: #000000;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  line-height: 15px;
  height: auto;
  padding: 20px 60px;
  box-sizing: border-box;
  transition: 0.3s;
}
.button-white:hover {
  background-color: #256861;
  color: #ffffff;
}
.button-disabled {
  background-color: #3f3f3f;
  border: none !important;
  border-radius: 6px;
  -webkit-box-shadow: 8px 8px 18px -4px rgba(127, 129, 152, 1);
  -moz-box-shadow: 8px 8px 18px -4x rgba(127, 129, 152, 1);
  box-shadow: 8px 8px 18px -4px rgba(127, 129, 152, 1);
  margin-top: var(--dl-space-space-doubleunit);
  color: #aaaaaa;
  cursor: not-allowed;
  font-size: 24px;
  font-weight: 600;
  line-height: 15px;
  height: auto;
  padding: 20px 60px;
  box-sizing: border-box;
  transition: 0.3s;
}
.button-disabled:hover {
  background-color: #1d2726;
  color: #999999;
}
.fiveunits-margin {
  margin-top: var(--dl-space-space-fiveunits);
}
.burger-menu {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all .3s ease-in-out;
  width: 100%;
  max-width: 100vw;
  height: 80px;
  box-shadow: -5px -5px 10px 0px #d4d4d4;
  background-color: var(--dl-color-gray-white);
  z-index: 100;
}
.burger-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 100vw;
  padding: var(--dl-space-space-doubleunit) var(--dl-space-space-doubleunit);
}
.menu-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.navlink {
  display: contents;
}
.menu-option {
  flex: 0 0 auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  align-content: center;
  flex-direction: column;
  text-decoration: none;
}
.menu-option:hover {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.menu-option:hover .icon {
  fill: var(--dl-color-PMA);
}
.menu-option:hover .text {
  color: var(--dl-color-PMA);
}
.menu-option:active {
  fill: var(--dl-color-PMA);
  color: var(--dl-color-PMA);
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.icon {
  fill: var(--dl-color-gray-black);
  width: 25px;
  height: 25px;
  transition: 0.3s;
}
.icon-active {
  fill: var(--dl-color-PMA);
  width: 25px;
  height: 25px;
  transition: 0.3s;
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.text {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  transition: 0.3s;
  font-weight: 600;
}
.text-active {
  color: var(--dl-color-PMA);
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.navbar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100px;
  z-index: 99;
  position: absolute;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  background-color: var(--dl-color-gray-white);
}
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-tripleunit);
}
.logolink {
  display: contents;
}
.pm-logo {
  width: 30%;
  transition: 0.3s;
  text-decoration: none;
  object-fit: cover;
}
.pm-logo:hover {
  transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
.links-container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.link {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
  transition: 0.3s;
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
  white-space: nowrap;
}
.link-active {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
  transition: 0.3s;
  margin-right: var(--dl-space-space-tripleunit);
  color: var(--dl-color-PMA);
  text-decoration: none;
  white-space: nowrap;
}
.linkkoncowy {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
  transition: 0.3s;
  text-decoration: none;
  white-space: nowrap;
}
.linkkoncowy-active {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
  transition: 0.3s;
  color: var(--dl-color-PMA);
  text-decoration: none;
  white-space: nowrap;
}
.link:hover,
.link:active,
.linkkoncowy:hover,
.linkkoncowy:active {
  color: var(--dl-color-PMA);
  transition: 0.3s;
  transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}
.polityka {
  font-weight: 600;
  text-decoration: underline;
}
.top-margin {
  margin-top: 100px;
}
.container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.tlo-jasne {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--dl-color-gray-1000);
}
.tlo-srednie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--dl-color-gray-900);
  margin-top: var(--dl-space-space-fiveunits);
}
.tlo-ciemne {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--dl-color-gray-500);
  margin-top: var(--dl-space-space-fiveunits);
}
.max-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--dl-size-size-maxcontent);
  padding: var(--dl-space-space-eightunits) var(--dl-space-space-fiveunits);
}
.tytul-container {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px var(--dl-space-space-fiveunits);
  margin-top: var(--dl-space-space-tripleunit);
}
.tytul {
  font-size: 42px;
  font-family: Arial;
  margin-bottom: var(--dl-space-space-doubleunit);
  font-weight: 600;
}
.podtytul {
  font-size: 24px;
  font-family: Arial;
  margin-bottom: var(--dl-space-space-doubleunit);
  color: var(--dl-color-PMA);
  font-weight: 600;
  text-transform: uppercase;
}
.naglowek {
  font-size: 32px;
  font-family: Arial;
  margin-bottom: var(--dl-space-space-doubleunit);
  font-weight: 600;
}
.podnaglowek {
  font-size: 24px;
  font-family: Arial;
  text-align: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  font-weight: 600;
}
.tekst {
  display: flex;
  overflow: hidden;
  padding-bottom: var(--dl-space-space-fiveunits);
  padding-top: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.akapit,
li {
  font-size: 18px;
  font-weight: normal;
}
.akapit-just {
  text-align: justify;
  font-size: 18px;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.pogrubiony {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.produkty-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: var(--dl-size-size-maxcontent);
}
.produkty {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.produkt {
  width: 33.3%;
  display: flex;
  align-items: center;
  padding: var(--dl-space-space-tripleunit) var(--dl-space-space-unit);
  flex-direction: column;
}
.ikona-produktu {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: var(--dl-space-space-tripleunit) var(--dl-space-space-doubleunit);
  margin-top: 0px;
  fill: var(--dl-color-gray-500);
  width: 75px;
  height: 75px;
  transition: 0.5s;
}
.produkt:hover .ikona-produktu {
  fill: var(--dl-color-PMA);
  -webkit-transform: scale(1.2) rotate(360deg);
  -moz-transform: scale(1.2) rotate(360deg);
  -ms-transform: scale(1.2) rotate(360deg);
  -o-transform: scale(1.2) rotate(360deg);
  transform: scale(1.2) rotate(360deg);
}
.nazwa-produktu {
  font-size: 24px;
  font-style: normal;
  font-family: Arial;
  text-align: center;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
  transition: 0.5s;
}
.produkt:hover .nazwa-produktu {
  color: var(--dl-color-PMA);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.opis-produktu {
  text-align: center;
  font-size: 20px;
}
.section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
  position: relative;
  z-index: 30;
}
.footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
  z-index: 99;
}
.social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-right: var(--dl-space-space-halfunit);
}
.footer-ikona {
  width: 28px;
  height: 28px;
  margin-right: var(--dl-space-space-tripleunit);
  transition: 0.3s;
}
.footer-ikona:hover,
.telefon-ikona:hover {
  fill: var(--dl-color-PMA);
  -webkit-transform: scale(1.7) rotate(360deg);
  -moz-transform: scale(1.7) rotate(360deg);
  -ms-transform: scale(1.7) rotate(360deg);
  -o-transform: scale(1.7) rotate(360deg);
  transform: scale(1.7) rotate(360deg);
}
.footer-link {
  display: contents;
}
.telefon-ikona {
  width: 28px;
  height: 28px;
  display: none;
  margin-right: var(--dl-space-space-tripleunit); 
  transition: 0.3s;
}
.rzetelna-ikona {
  width: auto;
  height: 40px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.rzetelna-ikona:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.copyright {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.pmautomation {
  text-align: right;
}
@media (max-width: 991px) {
  .button-black,
  .button-white,
  .button-disabled {
    font-size: 20px;
    line-height: 15px;
    padding: 18px 50px;
  }
  .logo {
    width: 30%;
  }
  .links-container {
    width: 60%;
  }
  .link,
  .link-active,
  .linkkoncowy,
  .linkkoncowy-active {
    font-size: 18px;
  }
  .fiveunits-margin {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .tytul {
    font-size: 32px;
    align-self: center;
    text-align: center;
  }
  .podtytul {
    font-size: 24px;
    align-self: center;
    text-align: center;
  }
  .naglowek {
    font-size: 30px;
    align-self: center;
    text-align: center;
  }
  .podnaglowek {
    font-size: 22px;
    align-self: center;
    text-align: center;
  }
  .pogrubiony {
    font-size: 18px;
  }
  .akapit {
    font-size: 16px;
  }
  .akapit-just {
    font-size: 16px;
  }
  .ikona-produktu {
    width: 60px;
    height: 60px;
  }
  .nazwa-produktu {
    font-size: 20px;
  }
  .opis-produktu {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .burger-menu {
    display: block;
  }
  .burger-container,
  .navbar {
    justify-content: center;
  }
  .pm-logo {
    width: 60%;
  }
  .links-container {
    display: none;
  }
  .button-black,
  .button-white
  .button-disabled {
    font-size: 18px;
    line-height: 15px;
    padding: 17px 40px;
  }
  .cookie-buttons {
    width: 30%;
    margin-left: var(--dl-space-space-doubleunit);
  }
  .fiveunits-margin {
    margin-top: 0px;
  }
  .tytul {
    font-size: 30px;
  }
  .podtytul {
    font-size: 22px;
  }
  .naglowek {
    font-size: 28px;
  }
  .podnaglowek {
    font-size: 20px;
  }
  .pogrubiony {
    font-size: 16px;
    text-align: center;
  }
  .tekst {
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .akapit,
  .akapit-just {
    text-align: center;
    font-size: 15px;
  }
  .produkt {
    width: 100%;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .ikona-produktu {
    width: 50px;
    height: 50px;
  }
  .nazwa-produktu {
    font-size: 24px;
  }
  .opis-produktu {
    font-size: 18px;
  }
  .footer-container {
    margin-bottom: 80px;
  }
  .footer-ikona {
    margin-right: var(--dl-space-space-doubleunit);
  }
  .telefon-ikona {
    display: flex;
    margin-right: var(--dl-space-space-doubleunit);
  }
}
@media (max-width: 590px) {
  .menu-container {
    justify-content: space-between;
  }
  .cookie {
    width: 30%;
    height: 30%;
  }
  .produkty {
    justify-content: center;
  }
  .ikona-produktu {
    width: 50px;
    height: 50px;
  }
  .footer-ikona,
  .telefon-ikona {
    margin-right: 10px;
  }
  .footer-ikona:hover,
  .telefon-ikona:hover {
    -webkit-transform: scale(1.5) rotate(360deg);
    -moz-transform: scale(1.5) rotate(360deg);
    -ms-transform: scale(1.5) rotate(360deg);
    -o-transform: scale(1.5) rotate(360deg);
    transform: scale(1.5) rotate(360deg);
  }
  .pmautomation {
    font-size: 14px;
  }
}
@media (max-width: 560px) {
  .cookie-buttons {
    align-self: center;
    justify-self: center;
    max-width: 100%;
    width: 100%;
    margin: 0px var(--dl-space-space-fiveunits);
  }
}
@media (max-width: 479px) {
  .cookie {
    width: 40%;
    height: 40%;
  }
  .icon {
    width: 25px;
    height: 25px;
  }
  .text {
    font-size: 16px;
  }
  .icon-active {
    width: 25px;
    height: 25px;
  }
  .text-active {
    font-size: 16px;
  }
  .pm-logo {
    width: 90%;
  }
  .button-black,
  .button-white,
  .button-disabled {
    font-size: 16px;
    line-height: 14px;
    padding: 15px 30px;
  }
  .tytul {
    font-size: 32px;
  }
  .podtytul {
    font-size: 22px;
  }
  .naglowek {
    font-size: 28px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .podnaglowek {
    font-size: 22px;
  }
  .tekst {
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .akapit,
  .akapit-just {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .nazwa-produktu {
    font-size: 20px;
  }
  .opis-produktu {
    font-size: 16px;
  }
  .footer {
    padding: 24px 12px;
  }
  .footer-ikona,
  .telefon-ikona {
    width: 23px;
    height: 23px;
  }
  .footer-ikona:hover,
  .telefon-ikona:hover {
    -webkit-transform: scale(1.3) rotate(360deg);
    -moz-transform: scale(1.3) rotate(360deg);
    -ms-transform: scale(1.3) rotate(360deg);
    -o-transform: scale(1.3) rotate(360deg);
    transform: scale(1.3) rotate(360deg);
  }
  .copyright {
    width: 25%;
  }
  .pmautomation {
    font-size: 12px;
  }
}