.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    width: 100%;
    padding-top: 100px;
}
#particles {
    display: flex;
    position: absolute;
    align-items: stretch;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
}
.logo-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    overflow: hidden;
    width: 100%;
    padding: var(--dl-space-space-fiveunits) var(--dl-space-space-fiveunits);
    z-index: 1;
    box-sizing: border-box;
}
.logo {
    width: 30%;
    transition: 0.3s;
}
.tekst {
    font-weight: 600;
    font-size: 28px;
    font-family: Overpass;
    margin: var(--dl-space-space-tripleunit) 0px;
    text-align: center;
}
.logo:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}
@media (max-width: 991px) {
    .tekst {
    font-size: 24px;
    }
    .logo {
    width: 50%;
    }
}
@media (max-width: 767px) {
    .tekst {
    font-size: 24px;
    }
    .logo {
    width: 50%;
    }
}
@media (max-width: 479px) {
    .tekst {
    font-size: 20px;
    }
    .logo {
    width: 70%;
    }
}
